<template>
  <users-role-selector
    class="users-role-filter"
    v-bind="$attrs"
    position="is-bottom-left"
    :multiple="true"
    v-on="$listeners">
    <template v-slot:default="slotProps">
      <span class="role-count is-pulled-right">
        {{ getRoleCount(slotProps.item) }}
      </span>
    </template>
  </users-role-selector>
</template>

<script>
const UsersRoleSelector = () => import('@components/UsersRoleSelector')

export default {
  name: 'users-role-filter',
  components: {
    UsersRoleSelector
  },
  props: {
    ...UsersRoleSelector.props,
    users: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getRoleCount(role) {
      const filteredUsers = this.users.filter(user => user.role === role)
      return filteredUsers.length
    }
  }
}
</script>

<style lang="scss" scoped>
.users-role-filter {
  display: inline-block;
  max-width: 160px;

  &::v-deep .input {
    cursor: pointer;
    @extend %body;
  }

  .role-count {
    color: $grey-light;
  }
}
</style>
